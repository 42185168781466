<template>
    <div class="createStepOne">
        <div class="createStepOne-form">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-form-item label="创建名称" prop="name">
                    <el-input style="width: 500px;" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="行业分类" prop="industryClass">
                    <el-select v-model="form.industryClass" placeholder="请选择行业分类" style="width: 500px;">
                        <el-option v-for="(item,index) in industryList" :key="index" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="访问间隔" prop="interval">
                    <el-input style="width: 500px;" v-model="form.interval" placeholder="每个机器人客户访问间隔时间" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="响应时间" prop="responseTime">
                    <el-input style="width: 500px;" v-model="form.responseTime" placeholder="客户提问时间间隔回答的时间" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="客服模块说明">
                    <el-input style="width: 500px;" type="textarea" v-model="form.explain"></el-input>
                </el-form-item>
                <el-form-item label="违禁词">
                    <el-input style="width: 500px;" type="textarea" v-model="form.forbidden"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input style="width: 500px;" type="textarea" v-model="form.remark"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="createStepBottom">
            <el-button class="last-step" @click="toBack">上一步</el-button>
            <el-button class="next-step" @click="toNext">下一步</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "createStepOne",
        data(){
            return {
                industryList:[],
                editDataList:{},
                editTransmit:{
                    id:'',
                    editRobotList:[]
                },
                id:'',
                form:{
                    name:'',
                    industryClass:'',
                    interval:'',
                    responseTime:'',
                    explain:'',
                    remark:'',
                    forbidden:''
                },
                rules:{
                    name:[
                        { required: true, message: '请输入创建名称', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    industryClass:[
                        { required: true, message: '请选择行业分类', trigger: 'change' }
                    ],
                    interval:[
                        { required: true, message: '请输入机器人客户访问间隔时间', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ],
                    responseTime:[
                        { required: true, message: '请输入客户提问时间间隔', trigger: 'blur' },
                        { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                    ]
                }
            }
        },
        created() {
            this.getIndustryClassify();
            this.deitData()
        },
        activated() {
            this.deitData();
        },
        methods: {
            //编辑显示
            deitData(){
                if(this.$route.query.data){
                    let isString = typeof(this.$route.query.data) === 'string';
                    let editData = isString ? JSON.parse(this.$route.query.data) : this.$route.query.data;
                    this.editTransmit.id = editData.id;
                    this.editTransmit.editRobotList = isString ? editData.robot_data : editData.editRobotList;
                    this.form.name = isString ? editData.kf_name :editData.name ;
                    this.form.industryClass =!isString ? editData.industryClass : !editData.category_id? null : editData.category_id;
                    this.form.interval = isString ?editData.pv_interval + '' : editData.interval;
                    this.form.responseTime = isString ? editData.response_interval + '' : editData.interval;
                    this.form.explain = editData.explain;
                    this.form.forbidden = editData.forbidden;
                    this.form.remark = editData.remark;
                } else {
                    this.editTransmit.id = '';
                    this.editTransmit.editRobotList = [];
                    this.form.name = '';
                    this.form.industryClass = '';
                    this.form.interval = '';
                    this.form.responseTime = '';
                    this.form.explain = '';
                    this.form.forbidden = '';
                    this.form.remark = '';
                    this.$nextTick(() => {
                        this.$refs['form'].clearValidate();
                    })
                }
            },
            getIndustryClassify(){
                this.$http.axiosGetBy(this.$api.categoryList, {}, (res)=>{
                    if(res.code === 200){
                        this.industryList = res.data;
                    } else {
                        this.$message.warning(res.msg);
                        this.$router.go(-1)
                    }
                })
            },
            toBack(){
                    this.$router.push({
                        path:'/admin/storecustomer',
                    })
                    this.$refs['form'].resetFields();
            },
            toNext(){
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                    let classifyName = '';
                    this.industryList.map(item=>{
                        if(item.id === this.form.industryClass){
                            classifyName = item.name
                        }
                    })
                    let data = {
                        name:this.form.name,
                        industryClass: this.form.industryClass,
                        category_name:classifyName,
                        interval: this.form.interval,
                        responseTime: this.form.responseTime,
                        explain: this.form.explain,
                        remark: this.form.remark,
                        forbidden: this.form.forbidden
                    }
                    let formData = JSON.stringify(data);
                    let editTransmit = JSON.stringify(this.editTransmit);
                    if(this.$route.query.data){
                        this.$router.push({
                            path:'/admin/storecustomer/createsteptwo',
                            query:{
                                res: formData,
                                editTransmit:editTransmit
                            }
                        })
                    } else {
                        this.$router.push({
                            path:'/admin/storecustomer/createsteptwo',
                            query:{
                                res: formData,
                            }
                        })
                    }
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .createStepOne{
        height: 100%;
        padding: 30px;
        .createStepOne-form{
            /*padding-left: 300px*/
        }
        .createStepBottom{
            margin-top: 100px;
            /*margin-top: 30px;*/
            /*margin-bottom: 30px;*/
            display: flex;
            /*justify-content: center;*/
            margin-left: 30.6%;
            .last-step{
                width: 100px;
                background: #ffffff;
                color: #1122D8;
                border-color:#1122D8 ;
            }
            .next-step{
                width: 100px;
                background: #1122D8;
                color: #ffffff;
                border-color: #1122D8;
                margin-left: 20px;
            }
        }
    }
</style>